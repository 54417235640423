$(document).on("ready", function () {
  // Product List
  $("#list-view").click(function () {
    $("#content .product-grid")
      .addClass("product-list")
      .removeClass("product-grid row-cols-md-2 row-cols-lg-3");
    $("#grid-view").removeClass("active");
    $("#list-view").addClass("active");

    localStorage.setItem("display", "list");
  });

  // Product Grid
  $("#grid-view").click(function () {
    $("#content .product-list")
      .addClass("product-grid row-cols-md-2 row-cols-lg-3")
      .removeClass("product-list");

    $("#list-view").removeClass("active");
    $("#grid-view").addClass("active");

    localStorage.setItem("display", "grid");
  });

  if (localStorage.getItem("display") == "list") {
    $("#list-view").trigger("click");
    $("#list-view").addClass("active");
  } else {
    $("#grid-view").trigger("click");
    $("#grid-view").addClass("active");
  }
});

$(document).on("mouseup", '[data-action="sortProducts"]', function () {
  var $this = $(this);
  var url_link = $this.attr("data-href");

  if (url_link !== "undefined" && url_link) {
    sortProducts(url_link, $this, ".js-sortReload");
  }
});

$(".s-pagination").on("click", "#showMoreData", function () {
  var link = $(".s-pagination").first().find(".active").next().find("[href]");

  if (link.length) {
    sortProducts(link.attr("href"), $(this), false);
  }
});

function setLocation(curLoc) {
  try {
    history.pushState(null, null, curLoc);
    return;
  } catch (e) {}
  location.hash = "#" + curLoc;
}

function sortProducts(url_link, $this, sortRow = true) {
  var paginator = ".s-pagination";
  var conatiner = ".s-products";

  if (url_link) {
    $.ajax({
      url: url_link,
      type: "get",
      cache: false,
      dataType: "html",
      beforeSend: function () {
        $(conatiner).css("opacity", 0.5);
        $this.addClass("animated").prop("disabled", true);
      },
      complete: function () {
        $(conatiner).attr("style", null);
        $this.removeClass("animated").prop("disabled", false);
      },
      success: function (html) {
        if (html) {
          var $html = $(html);
          var $prodCont = $html.find(conatiner);
          var $paginCont = $html.find(paginator);

          if ($prodCont.length) {
            if (sortRow) {
              var $sortRow = $html.find(sortRow);
              $(sortRow).each(function (index, el) {
                $(el).html($sortRow.eq(index).html());
              });
              $(conatiner).html($prodCont.html());
            } else {
              $(conatiner).append($prodCont.html());
            }
            $(paginator).each(function (index, el) {
              $(el).html($paginCont.eq(index).html());
            });
          }

          setLocation(url_link);
        }
      },
      error: function () {
        console.log("error");
      },
    });
  }
}

// Max height for category
$(function () {
  var visibleItems = 10;
  var hideItems = 2;

  var itemList = $(".js-categoryList");
  var count = itemList.find("a").filter(":visible").size() - visibleItems;

  if (count > 0 && count >= hideItems) {
    if (itemList.hasClass("js-categoryExpanded")) {
      expandCategories(itemList);
    } else {
      shrinkCategories(itemList, visibleItems);
    }
  } else {
    itemList.css("height", "auto");
    itemList.parent().find(".js-showCategoryList").addClass("d-none");
  }

  $(".js-showCategoryList").on("click", function () {
    if (!itemList.hasClass("js-categoryExpanded")) {
      expandCategories(itemList);
    } else {
      shrinkCategories(itemList, visibleItems);
    }
  });
});

function expandCategories(itemList) {
  var fullHeight = $(itemList).find(".aside-categories__wrapper").outerHeight();

  itemList.stop().animate({ height: fullHeight }, 300);
  $(".js-showCategoryList").html($(".js-showCategoryList").data("text-hide"));

  itemList.addClass("js-categoryExpanded");
}
function shrinkCategories(itemList, visibleItems) {
  var height = 0;

  if (visibleItems) {
    $(itemList)
      .find("a")
      .each(function (i, v) {
        if (i < visibleItems) {
          height += $(v).outerHeight();
        }
      });
    $(itemList).stop().animate({ height: height }, 300);
    $(".js-showCategoryList").html($(".js-showCategoryList").data("text-show"));
  }

  itemList.removeClass("js-categoryExpanded");
}
